document.addEventListener("DOMContentLoaded", function () {

    /*Calculate elements height to set half circle pseudo element width*/
    function calcElementsHeight() {
        const elements = document.querySelectorAll('#job-hero, #organisation-hero, #hero .left-col, #hero-banner .right-col, .text-block.layout2');
        elements.forEach(element => {
            if (element) {
                const elementsHeight = element.getBoundingClientRect().height;
                element.style.setProperty('--element-height', `${elementsHeight}px`);
            }
        });
    }

    function closeSubCategories(subcategories, category) {
        const isOpen = category.classList.contains('open');
        if (isOpen) {
            const links = subcategories.querySelectorAll('a');
            links.forEach(link => {
                link.tabIndex = '-1';
            });
            subcategories.style.height = `${subcategories.scrollHeight}px`;
            requestAnimationFrame(() => {
                subcategories.style.height = '0';
            });
            category.classList.remove('open');
        }
    }

    function openSubCategories(subcategories, category) {
        const isOpen = category.classList.contains('open');
        if (!isOpen) {
            const links = subcategories.querySelectorAll('a');
            links.forEach(link => {
                link.tabIndex = '0';
            });
            subcategories.style.height = `${subcategories.scrollHeight}px`;
            category.classList.add('open');
            subcategories.addEventListener(
                'transitionend',
                () => {
                    subcategories.style.height = 'auto';
                },
                { once: true }
            );
        }
    }

    function toggleSubCategories(subcategories, category) {
        category.classList.contains('open') ? closeSubCategories(subcategories, category) : openSubCategories(subcategories, category);
    }

    // Jobs Accordions
    const categories = document.querySelectorAll('#jobs .category');
    categories.forEach((category) => {
        const subcategories = category.querySelector('.subcategories');
        category.addEventListener('click', function () {
            toggleSubCategories(subcategories, category);
        });

        category.addEventListener('keyup', function (event) {
            if (event.key === " ") {
                event.preventDefault();
                event.stopImmediatePropagation()
                toggleSubCategories(subcategories, category);
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                event.stopImmediatePropagation()
                closeSubCategories(subcategories, category);
            } else if (event.key === "ArrowDown") {
                event.preventDefault();
                event.stopImmediatePropagation()
                openSubCategories(subcategories, category);
            }
        });

        // Prevent the accordion from closing when clicking on a link
        const links = category.querySelectorAll('.subcategories a');
        links.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.stopPropagation();
            });
        });
    });

    // Adjust on page load
    calcElementsHeight();

    // Recalculate on window resize
    const resizeHandler = () => {
        calcElementsHeight();
    };

    window.addEventListener('resize', resizeHandler);

});
